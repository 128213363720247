import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import CardCustomer from '../components/CardCustomer';
import SecondaryButton from '../components/SecondaryButton';
import LocalizedLink from '../components/LocalizedLink';
import _SectionClients from '../components/SectionClients';


const CardCustomerLayout = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    margin-bottom:7%;
`;

const RowIconsCells = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin: 5% 0% 0% 0%;

    @media (min-width: 320px) and (max-width: 767px) {
        flex-direction:column;
    }
`;

const IconContent = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: baseline;

    @media (min-width: 320px) and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

const RowIconsDescription = styled.div`
    margin: 15px;
    width: 20%;

    @media (min-width: 320px) and (max-width: 767px) {
        margin: 9px;
        width: 43%;
    }
`;

const Picture = styled.img`
    max-width: 2vh;
`;

const TitleCell = styled.h2`
    font-size: 16px;
    font-weight: 300;
`;

const TextCell = styled.p`
    font-size: 14px;
    font-weight: 200;
`;

const CategoryIndustries = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin-top:3%;
`;

const TitleIndustries = styled.h1`
    font-size: 37px;
    font-weight: 100;
`;

const LinkIndustries = styled(LocalizedLink)`
    font-weight: 300;
    font-size: 17px;
    color: #b4d5ff;
    text-decoration: none;

    :hover{
        font-weight: 300;
        color:white;
    }
`;

const LinkRowIcon = styled(LocalizedLink)`
    display: block;
    margin-bottom: 3%;
`;

const SectionClients = styled(_SectionClients)`
    border-radius: 10px;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('industriesPage');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_INDUSTRIES')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_INDUSTRIES')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{__html: t('TITLE')}}></h1>

                    <SectionClients
                        phraseLogo={t('TITRE_LOGO_INDUSTRY')}
                        customersLogos={[
                            {
                                url: '/government/',
                                image: '/images/home/logo-ministere-culture.png'
                            },
                            {
                                url: '/travel/',
                                image: '/images/products/wemap-web/air-france.svg'
                            },
                            {
                                url: '/medias/',
                                image: '/images/products/wemap-web/ouest-france.svg'
                            },
                            {
                                url: '/government/',
                                image: '/images/industries/local-authorities/ile-de-france.svg'
                            },
                            {
                                url: '/government/',
                                image: '/images/industries/versailles-logo.png'
                            },
                            {
                                url: '/culture/',
                                image: '/images/products/games/louvre-lens.svg'
                            },
                            {
                                url: '/sport/',
                                image: '/images/industries/events/fff.png'
                            },
                            {
                                url: '/kiosk/',
                                image: '/images/products/wemap-navigation/logo_jc_decaux.png'
                            },
                            {
                                url: '/augmented-reality/',
                                image: '/images/industries/sncf-logo.png'
                            },
                            {
                                url: '/kiosk/',
                                image: '/images/industries/havre-logo.png'
                            }
                        ]}
                    />

                    <RowIconsCells>
                        <IconContent >
                            <RowIconsDescription>
                                <Picture src='/images/industries/eye-icon.svg'
                                    alt='' />
                                <TitleCell>{t('TITLE_NO_DEVELOPMENT')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_LINE_CODE')}</TextCell>
                            </RowIconsDescription>
                            <RowIconsDescription>
                                <Picture src='/images/industries/love-icon.svg'
                                    alt='' />
                                <TitleCell>{t('TITLE_HANDLING')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_SIMPLE_PLATFORM')}</TextCell>
                            </RowIconsDescription>
                            <RowIconsDescription className='wemap-container-row-icons-description'>
                                <Picture src='/images/industries/time-icon.svg'
                                    alt='' />
                                <TitleCell>{t('TITLE_PUBLICATION')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_CREATED_CONTENT')}</TextCell>
                            </RowIconsDescription>
                            <RowIconsDescription>
                                <Picture src='/images/industries/dollar-icon.svg'
                                    alt='' />
                                <TitleCell>{t('TITLE_TEAM_SUPPORT')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_TECHNICAL_TEAM')}</TextCell>
                            </RowIconsDescription>
                        </IconContent>
                    </RowIconsCells>

                    <LinkRowIcon to='/contact/'>
                        <SecondaryButton
                            titleButton={t('BUTTON_LEARN_MORE')} />
                    </LinkRowIcon>

                    <hr className='line'/>
                    <CategoryIndustries>
                        <TitleIndustries>{t('TITLE_MENU_MEDIA')}</TitleIndustries>
                        <LinkIndustries to='/medias/'>{t('TITLE_MENU_RIGHT_SEE_ALL')}</LinkIndustries>
                    </CategoryIndustries>

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://www.sudinfo.be/7dimanche/carte'
                            picturecard={data.dimanche.childImageSharp}
                            altPictureCard='La CNRS logo'
                            title={t('TITLE_DIMANCHE_MAP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_DIMANCHE_MAP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_DIMANCHE_MAP_POINT'),
                                    infoView: t('TITLE_DIMANCHE_MAP_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://esclavages.cnrs.fr/cartographie/'
                            picturecard={data.cnrs.childImageSharp}
                            altPictureCard='La CNRS logo'
                            title={t('TITLE_CNRS_MAP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_CNRS_MAP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_CNRS_MAP_POINT'),
                                    infoView: t('TITLE_CNRS_MAP_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.ladepeche.fr/2021/03/09/carte-dette-impots-depenses-votre-commune-est-elle-bien-geree-9417767.php'
                            picturecard={data.ladepecheimpot.childImageSharp}
                            altPictureCard='La Dépêche logo'
                            title={t('TITLE_IMPOT_MAP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_IMPOT_MAP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_IMPOT_MAP_POINT'),
                                    infoView: t('TITLE_IMPOT_MAP_VIEW')
                                }
                            ]}
                        />
                    </CardCustomerLayout>

                    <hr className='line'/>
                    <CategoryIndustries>
                        <TitleIndustries>{t('TITLE_MENU_TRAVEL')}</TitleIndustries>
                        <LinkIndustries to='/travel/'>{t('TITLE_MENU_RIGHT_SEE_ALL')}</LinkIndustries>
                    </CategoryIndustries>

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://www.airfrance.fr/guide-voyage/carte-destinations'
                            picturecard={data.airfrance.childImageSharp}
                            altPictureCard='Air France logo'
                            title={t('TITLE_AIR_FRANCE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_AIR_FRANCE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_AIR_FRANCE_POINT'),
                                    infoView: t('TITLE_AIR_FRANCE_VIEW')
                                }
                            ]}
                        />
                        <CardCustomer
                            url='https://www.mulhouse-alsace.fr/mobilites/randonnees-pedestres/'
                            picturecard={data.mulhouse.childImageSharp}
                            altPictureCard='Mulhouse logo'
                            title={t('TITLE_MULHOUSE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_MULHOUSE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_MULHOUSE_POINT'),
                                    infoView: t('TITLE_MULHOUSE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.tourisme-en-hautsdefrance.com/decouvrir/les-departements/le-pas-de-calais/'
                            picturecard={data.hautfrance.childImageSharp}
                            altPictureCard='Haut de France logo'
                            title={t('TITLE_HAUT_FRANCE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_HAUT_FRANCE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_HAUT_FRANCE_POINT'),
                                    infoView: t('TITLE_HAUT_FRANCE_VIEW')
                                }
                            ]}
                        />
                    </CardCustomerLayout>

                    <hr className='line'/>
                    <CategoryIndustries>
                        <TitleIndustries>{t('TITLE_MENU_LOCAL_AUTHORITIES')}</TitleIndustries>
                        <LinkIndustries to='/government/'>{t('TITLE_MENU_RIGHT_SEE_ALL')}</LinkIndustries>
                    </CategoryIndustries>

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://getwemap.com/louvre'
                            picturecard={data.louvre.childImageSharp}
                            altPictureCard='Musée du Louvre logo'
                            title={t('TITLE_LOUVRE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_LOUVRE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_LOUVRE_POINT'),
                                    infoView: t('TITLE_LOUVRE_VIEW')
                                }
                            ]}
                        />
                        <CardCustomer
                            url='https://www.grandest.fr/agenda/sorties-nature/'
                            picturecard={data.grandest.childImageSharp}
                            altPictureCard='Haut de France logo'
                            title={t('TITLE_GRAND_EST')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_GRAND_EST_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_GRAND_EST_POINT'),
                                    infoView: t('TITLE_GRAND_EST_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.hautsdefrance-propres.fr/'
                            picturecard={data.hautfrancelocal.childImageSharp}
                            altPictureCard='Haut de France logo'
                            title={t('TITLE_HAUT_FRANCE_LOCAL')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_HAUT_FRANCE_LOCAL_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_HAUT_FRANCE_LOCAL_POINT'),
                                    infoView: t('TITLE_HAUT_FRANCE_LOCAL_VIEW')
                                }
                            ]}
                        />

                    </CardCustomerLayout>

                    <hr className='line'/>
                    <CategoryIndustries>
                        <TitleIndustries>{t('TITLE_MENU_EVENTS')}</TitleIndustries>
                        <LinkIndustries to='/events/'>{t('TITLE_MENU_RIGHT_SEE_ALL')}</LinkIndustries>
                    </CategoryIndustries>

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://icom.museum/fr/news/la-carte-interactive-de-la-journee-internationale-des-musees-2021-est-en-ligne/'
                            picturecard={data.icom.childImageSharp}
                            altPictureCard='Logo de Icom Musee'
                            title={t('TITLE_ICOM')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_ICOM_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_ICOM_POINT'),
                                    infoView: t('TITLE_ICOM_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.napoleoncities.eu/'
                            picturecard={data.napoleon.childImageSharp}
                            altPictureCard='Logo de Napoleon Cities'
                            title={t('TITLE_NAPOLEON')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_NAPOLEON_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_NAPOLEON_POINT'),
                                    infoView: t('TITLE_NAPOLEON_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://rendezvousauxjardins.culture.gouv.fr/Programme'
                            picturecard={data.rdvj.childImageSharp}
                            altPictureCard='Rendez-vous aux Jardins pciture'
                            title={t('TITLE_RDVJ')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_RDVJ_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_RDVJ_POINT'),
                                    infoView: t('TITLE_RDVJ_VIEW')
                                }
                            ]}
                        />

                    </CardCustomerLayout>

                    <hr className='line'/>
                    <CategoryIndustries>
                        <TitleIndustries>{t('TITLE_MENU_SPORT')}</TitleIndustries>
                        <LinkIndustries to='/sport/'>{t('TITLE_MENU_RIGHT_SEE_ALL')}</LinkIndustries>
                    </CategoryIndustries>

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://www.tottenhamhotspur.com/we-map-mobile-test/'
                            picturecard={data.tottenham.childImageSharp}
                            altPictureCard='Logo de Tottenham'
                            title={t('TITLE_TOTTENHAM')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_TOTTENHAM_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_TOTTENHAM_POINT'),
                                    infoView: t('TITLE_TOTTENHAM_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.psg.fr/fans/fan-clubs#/search@-0.0000000,0.0000000,3.00'
                            picturecard={data.psg.childImageSharp}
                            altPictureCard='Logo de PSG'
                            title={t('TITLE_PSG')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_PSG_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_PSG_POINT'),
                                    infoView: t('TITLE_PSG_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.fff.fr/13-les-pratiques-loisirs/index.html'
                            picturecard={data.fff.childImageSharp}
                            altPictureCard='Logo de FFF'
                            title={t('TITLE_FFF')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_FFF_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_FFF_POINT'),
                                    infoView: t('TITLE_FFF_VIEW')
                                }
                            ]}
                        />

                    </CardCustomerLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with computer and mobile view of Wemap map'
                        titleBannerPicture={t('TITLE_BANNER')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  dimanche: file(relativePath: {eq: "industries/media/picture_7dimanche.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  cnrs: file(relativePath: {eq: "industries/media/card-horizontal-cnrs.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ladepecheimpot: file(
    relativePath: {eq: "industries/media/picture_ladepeche.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  hautfrance: file(
    relativePath: {eq: "industries/travel/picture_haut_de_france.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  mulhouse: file(relativePath: {eq: "industries/travel/picture_mulhouse.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  airfrance: file(relativePath: {eq: "industries/travel/picture_airfrance.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  louvre: file(
    relativePath: {eq: "industries/local-authorities/picture_louvre.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  hautfrancelocal: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-haut-de-france.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  grandest: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-grand-est.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  icom: file(relativePath: {eq: "industries/events/card-horizontal-icom.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  napoleon: file(
    relativePath: {eq: "industries/events/card-horizontal-napoleon.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  rdvj: file(
    relativePath: {eq: "industries/events/card-horizontal-rdvj-2019.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  tottenham: file(
    relativePath: {eq: "industries/sport/card-horizontal-tottenham.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  psg: file(relativePath: {eq: "industries/sport/card-horizontal-psg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  fff: file(relativePath: {eq: "industries/sport/card-horizontal-fff.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  banner: file(relativePath: {eq: "industries/banner-picture-industries.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
